import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Whitepaper'),
        href: 'whitepaper.pdf',
        isHighlighted: true,
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Customer Support'),
        href: 'https://t.me/grokxl',
      },
    ],
  },
  {
    label: t('Contact us'),
    items: [
      {
        label: 'info@grokxl.com',
        href: 'mailto:info@grokxl.com',
      },
      
    ],
  },
]
