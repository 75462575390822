import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Limit'),
          href: '/limit-orders',
          supportChainIds: SUPPORT_ONLY_BSC,
          image: '/images/decorations/3d-coin.png',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
	{
      label: 'Games',
      href: 'https://www.grokxl.ai/',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: t('GrokXL Games'),
          href: 'https://www.grokxl.ai/',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
	{
	label: 'Whitepaper',
	href: '/whitepaper.pdf',
	icon: MoreIcon,
	hideSubNav: true,
	items: [
		{
      label: t('Whitepaper'),
      href: '/whitepaper.pdf',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
	].map((item) => addMenuItemSupported(item, chainId)),
    },
		{
	label: 'Telegram',
	href: 'https://t.me/grokxl',
	icon: MoreIcon,
	hideSubNav: true,
	items: [
    {
      label: t('Telegram'),
      href: 'https://t.me/grokxl',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
	].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: 'info',
      href: '/info',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: t('Info'),
          href: '/info',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
